import { createStore as reduxCreateStore } from "redux"

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_FILTER_1":
      return Object.assign({}, state, {
        ...state,
        filter1: action.value,
      })
    case "SET_FILTER_2":
      return Object.assign({}, state, {
        ...state,
        filter2: action.value,
      })
    default:
      return state
  }
}

const initialState = {
  filter1: null,
  filter2: { value: null, label: null },
}

const createStore = () => reduxCreateStore(reducer, initialState)

export default createStore
